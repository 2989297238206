import Menu from "./components/Menu";
import React from "react";
import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import UsersList from "./pages/UsersList";
import Support from "./pages/Support";
import VideoCalls from "./pages/VideoCalls";
import Dashboard from "./pages/Dashboard";
import TopTopics from "./pages/TopTopics";
import Finances from "./pages/Finances";
import Auth from "./pages/Auth";
import UserService from "./UserService";
import ChooseChat from "./pages/ChooseChat";
import { connectToSocketIO } from "./SignallingChannel";
import ChooseUser from "./pages/ChooseUser";
import TeacherRequests from "./pages/TeacherRequests";
import VideoChatInfo from "./pages/VideoChatInfo";
import WithdrawRequests from "./pages/WithdrawRequests";

class App extends React.Component {
  state = {
    selectedPage: "",
    AuthScreenVisible: false,
  };

  async checkAuth() {
    let isLogged = await UserService.isLogged();
    this.setState({
      AuthScreenVisible: !isLogged,
    });
  }

  componentDidMount() {
    this.checkAuth();
    connectToSocketIO();
  }

  render() {
    return (
      <IonApp>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Route path="/users" component={UsersList} exact />
              <Route path="/users/:userId" component={ChooseUser} exact />
              <Route path="/top-topics" component={TopTopics} exact />
              <Route path="/videocalls" component={VideoCalls} exact />
              <Route path="/videocalls/:callId" component={VideoChatInfo} exact />
              <Route path="/support" component={Support} exact />
              <Route path="/support/:chatId/:interlocutorId" component={ChooseChat} exact={true} />
              <Route path="/dashboard" component={Dashboard} exact />
              <Route path="/finances" component={Finances} exact />
              <Route path="/finances/withdraw" component={WithdrawRequests} exact />
              <Route path="/teacher-requests" component={TeacherRequests} exact />
              
              
              <Redirect from="/" to="/dashboard" exact />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>

        <Auth
          isVisible={this.state.AuthScreenVisible}
          isLogged={() => {
            this.setState({
              AuthScreenVisible: !UserService.isLogged(),
            });
          }}
        />
      </IonApp>
    );
  }
}

export default App;
