import {
  IonButtons,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonContent,
  IonBackButton,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonChip,
  IonLabel,
  IonCardSubtitle,
  IonItem,
  IonList,
  IonIcon,
  IonItemDivider,
  IonButton,
  IonAvatar,
} from "@ionic/react";
import React from "react";
import { RouteComponentProps } from "react-router";
import API from "../API";
import {
  arrowDown,
  time,
  arrowUp,
  timerSharp,
  information,
} from "ionicons/icons";

interface ChooseUserProps
  extends RouteComponentProps<{
    userId: string;
  }> {}

export default class extends React.Component<ChooseUserProps> {
  state: any = {
    user: null,
    chat: null,
  };

  componentDidMount() {
    API.getUserInfo(this.props.match.params.userId)
      .then((res: any) => {
        this.setState(res.data);
      })
      .finally(() =>
        API.getChatByUserId(this.props.match.params.userId).then((res) =>
          this.setState({
            chat: res.data,
          })
        )
      );
  }

  getIconByStatus(state: any) {
    switch (state) {
      case 0:
        return <IonIcon slot="start" icon={time} color="danger"></IonIcon>;
      case 1:
        return (
          <IonIcon slot="start" icon={arrowDown} color="success"></IonIcon>
        );
      case 2:
        return (
          <IonIcon slot="start" icon={timerSharp} color="warning"></IonIcon>
        );
      case 3:
        return <IonIcon slot="start" icon={arrowUp} color="primary"></IonIcon>;
      default:
        return (
          <IonIcon slot="start" icon={information} color="primary"></IonIcon>
        );
    }
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/"></IonBackButton>
            </IonButtons>
            <IonTitle>Пользователь</IonTitle>
            <IonButtons slot="end">
              {this.state.user != null && this.state.chat != null ? (
                <IonButton
                  routerLink={
                    "/support/" + this.state.chat.id + "/" + this.state.user.id
                  }
                >
                  Открыть чат с пользователем
                </IonButton>
              ) : (
                false
              )}
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          {this.state.user == null ? (
            false
          ) : (
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{this.state.user.name}</IonCardTitle>
                <IonCardSubtitle>
                  {this.state.user.is_teacher ? "Учитель" : "Ученик"}
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                {this.state.user.avatar ? (
                  <IonAvatar
                    onClick={() => {
                      window.open(this.state.user.avatar, "_blank");
                    }}
                  >
                    <img src={this.state.user.avatar} />
                  </IonAvatar>
                ) : (
                  <IonLabel color="danger">Аватар отсутствует</IonLabel>
                )}
                <br />

                <p>ID: {this.state.user.id}</p>
                <p>
                  Статус учителя:{" "}
                  {this.state.user.is_verified_teacher
                    ? "Верифицированный учитель"
                    : "Не верифицирован"}
                </p>
                <p>
                  Email: {this.state.user.email || "-"} |{" "}
                  {this.state.user.email_verified == 0
                    ? "Не верифицирован"
                    : "Проверен"}
                </p>
                <p>
                  Телефон: {this.state.user.phone || "-"} |{" "}
                  {this.state.user.phone_verified == 0
                    ? "Не верифицирован"
                    : "Проверен"}
                </p>
                <p>Стоимость: {this.state.user.cost / 100} евро в минуту</p>
                <p>О себе: {this.state.user.about}</p>
                <p>Язык на телефоне: {this.state.user.locale}</p>
                <p>Баланс: {this.state.user.balance / 100} евро</p>
                <p>
                  PayPal ID:{" "}
                  <strong>
                    {this.state.user.paypal_account || "Не указан"}
                  </strong>
                </p>
                <p>
                  Branch data:{" "}
                  <strong>
                    {this.state.user.branch_data || "Не получены данные"}
                  </strong>
                </p>
                <br />
                <p>Интересы</p>
                {this.state.user.topics.map((topic: any) => {
                  return (
                    <IonChip key={topic.id}>
                      <IonLabel>{topic.title}</IonLabel>
                    </IonChip>
                  );
                })}
                <p>Владеет языками</p>
                {this.state.user.know_languages.map((topic: any) => {
                  return (
                    <IonChip key={topic.id}>
                      <IonLabel>{topic.title}</IonLabel>
                    </IonChip>
                  );
                })}
                <p>
                  {this.state.user.is_teacher ? "Учит языкам" : "Учится языкам"}
                </p>
                {this.state.user.teaching_languages.map((topic: any) => {
                  return (
                    <IonChip key={topic.id}>
                      <IonLabel>{topic.title}</IonLabel>
                    </IonChip>
                  );
                })}

                <br />
                <br />

                <IonList>
                  <IonItemDivider>Транзакции</IonItemDivider>
                  {this.state.transactions.map((t: any) => {
                    return (
                      <IonItem key={t.id}>
                        {this.getIconByStatus(t.status)}
                        <IonLabel>
                          <h2>
                            Сумма: {Math.round(t.amount / 100)} | {t.comment}
                          </h2>
                          <p>{t.created}</p>
                        </IonLabel>
                      </IonItem>
                    );
                  })}
                </IonList>

                <IonList>
                  <IonItemDivider>Сессии пользователя</IonItemDivider>
                  {this.state.sessions.map((session: any) => {
                    return (
                      <IonItem key={session.id}>
                        <IonLabel>
                          <h2>Платформа: {session.os}</h2>
                          <p>Версия ОС: {session.os_version}</p>
                          <p>Создан: {session.created}</p>
                          <p>
                            Последняя активность: {session.last_active_date}
                          </p>
                        </IonLabel>
                        {session.is_active ? (
                          <IonButton
                            color="danger"
                            onClick={async () => {
                              let sessions = await API.changeSessionState(
                                session.id,
                                session.user_id,
                                false
                              );
                              if (sessions.success) {
                                this.setState({
                                  sessions: sessions.data,
                                });
                              }
                            }}
                          >
                            Выключить сессию
                          </IonButton>
                        ) : (
                          <IonButton
                            onClick={async () => {
                              let sessions = await API.changeSessionState(
                                session.id,
                                session.user_id,
                                true
                              );
                              if (sessions.success) {
                                this.setState({
                                  sessions: sessions.data,
                                });
                              }
                            }}
                            color="success"
                          >
                            Активировать сессию
                          </IonButton>
                        )}
                      </IonItem>
                    );
                  })}
                </IonList>
              </IonCardContent>
            </IonCard>
          )}
        </IonContent>
      </IonPage>
    );
  }
}
