import React from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonLabel,
  IonItem,
  IonInput,
  IonTabButton,
  IonIcon,
} from "@ionic/react";
import { chatbubbleEllipses, logIn } from "ionicons/icons";
import API from "../API";
import UserService from "../UserService";

export interface AuthInterface {
  isVisible: boolean;
  isLogged: () => void;
}

export default class extends React.Component<AuthInterface> {
  state = {
    codeRequested: false,
  };

  email = "";
  code = "";

  async requestCode() {
    let responce = await API.requestCode(this.email);

    console.log(responce);

    if (responce.success) {
      this.setState({
        codeRequested: true,
      });
      console.log(responce);
      console.log(this.state);
    } else {
      alert(responce.error);
    }
  }

  async confirmCode() {
    let responce = await API.confirmCode(this.email, this.code);

    console.log(responce);

    if (responce.success) {
      UserService.setAccessToken(responce.data.access_token);
      this.props.isLogged();
    } else {
      alert(responce.error);
    }
  }

  render() {
    return (
      <div hidden={!this.props.isVisible}>
        <div
          style={{
            width: "100vw",
            height: "100%",
            textAlign: "center",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            position: "absolute",
          }}
        />

        <div
          style={{ position: "absolute", width: "100%", textAlign: "center" }}
        >
          <IonCard style={{ marginTop: "35vh", paddingBottom: 32 }}>
            <IonCardHeader>
              <IonCardSubtitle>Авторизация</IonCardSubtitle>
              <IonCardTitle>Talkout Панель управления</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonLabel>Войдите в систему</IonLabel>
              <IonItem>
                <IonLabel position="floating">Email</IonLabel>
                <IonInput
                  placeholder="mail@example.com"
                  defaultValue=""
                  onIonChange={(event) => (this.email = event.detail.value!)}
                ></IonInput>
              </IonItem>
              {this.state.codeRequested ? (
                <IonItem>
                  <IonLabel position="floating">Код подтверждения</IonLabel>
                  <IonInput
                    placeholder="XXX-XXX"
                    defaultValue=""
                    onIonChange={(event) => (this.code = event.detail.value!)}
                  ></IonInput>
                </IonItem>
              ) : (
                false
              )}

              {!this.state.codeRequested ? (
                <IonTabButton onClick={() => this.requestCode()}>
                  <IonIcon icon={chatbubbleEllipses}></IonIcon>
                  <IonLabel>Запросить код</IonLabel>
                </IonTabButton>
              ) : (
                <IonTabButton onClick={() => this.confirmCode()}>
                  <IonIcon icon={logIn}></IonIcon>
                  <IonLabel>Подтвердить код</IonLabel>
                </IonTabButton>
              )}
            </IonCardContent>
          </IonCard>
        </div>
      </div>
    );
  }
}
