import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from "@ionic/react";

import React from "react";
import { useLocation } from "react-router-dom";
import {
  archiveOutline,
  archiveSharp,
  bookmarkOutline,
  heartOutline,
  heartSharp,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  trashOutline,
  trashSharp,
  warningOutline,
  warningSharp,
  person,
  camera,
  headset,
  home,
  analytics,
  cash,
  personAdd,
  arrowDown,
} from "ionicons/icons";
import "./Menu.css";
import UserService from "../UserService";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: "Главная",
    url: "/dashboard",
    iosIcon: home,
    mdIcon: home,
  },
  {
    title: "Пользователи",
    url: "/users",
    iosIcon: person,
    mdIcon: person,
  },
  {
    title: "Видеозвонки",
    url: "/videocalls",
    iosIcon: camera,
    mdIcon: camera,
  },
  {
    title: "Поддержка",
    url: "/support",
    iosIcon: headset,
    mdIcon: headset,
  },
  {
    title: "Топ интересов",
    url: "/top-topics",
    iosIcon: analytics,
    mdIcon: analytics,
  },
  {
    title: "Финансы",
    url: "/finances",
    iosIcon: cash,
    mdIcon: cash,
  },
  {
    title: "Запросы на учителей",
    url: "/teacher-requests",
    iosIcon: personAdd,
    mdIcon: personAdd,
  },
  {
    title: "Вывод денег",
    url: "/finances/withdraw",
    iosIcon: arrowDown,
    mdIcon: arrowDown,
  },
];

const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Панель управления</IonListHeader>
          <IonNote></IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
          <br/><br/>
          <IonButton onClick={() => {
            UserService.dropUserData();
            document.location.href = "/";
          }} color="danger">Выйти</IonButton>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
