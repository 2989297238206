import React from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonItemDivider,
} from "@ionic/react";
import API from "../API";

export default class extends React.Component {
  state = {
    topics: [],
  };

  filterStartDate = new Date().toISOString();
  filterEndDate = new Date().toISOString();

  async getData() {
    let responce = await API.getTopics(
      this.filterStartDate.split("T")[0],
      this.filterEndDate.split("T")[0]
    );

    this.setState({
      topics: responce.data,
    });
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Топ интересов</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList>
            <IonItemDivider>Интервал времени</IonItemDivider>
            <IonItem>
              <IonLabel position="floating">Выберите дату от</IonLabel>
              <IonDatetime
                displayFormat="MM/DD/YYYY"
                value={this.filterStartDate}
                onIonChange={(event) => {
                  this.filterStartDate =
                    event.detail.value ?? this.filterStartDate;
                  this.getData();
                }}
              ></IonDatetime>
            </IonItem>

            <IonItem>
              <IonLabel position="floating">Выберите дату до</IonLabel>
              <IonDatetime
                displayFormat="MM/DD/YYYY"
                value={this.filterEndDate}
                onIonChange={(event) => {
                  this.filterEndDate = event.detail.value ?? this.filterEndDate;
                  this.getData();
                }}
              ></IonDatetime>
            </IonItem>
            <IonItemDivider>Топики</IonItemDivider>
            {this.state.topics.map((topic: any) => {
              return (
                <IonItem key={topic.topic_id}>
                  <IonLabel>
                    <h2>{topic.title}</h2>
                    <p>{new Date(topic.created).toString()}</p>
                  </IonLabel>
                  <IonLabel style={{ textAlign: "right" }} slot="end">
                    {topic.score}
                  </IonLabel>
                </IonItem>
              );
            })}
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
}
