import {
  IonButtons,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonContent,
  IonBackButton,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonChip,
  IonLabel,
  IonCardSubtitle,
  IonItem,
  IonList,
  IonIcon,
  IonItemDivider,
} from "@ionic/react";
import React from "react";
import { RouteComponentProps } from "react-router";
import API from "../API";

interface VideoChatInfoProps
  extends RouteComponentProps<{
    callId: string;
  }> {}

export default class extends React.Component<VideoChatInfoProps> {
  state: any = {
    videoChat: null,
  };

  async getData() {
    let responce = await API.getVideocallsFullInfo(
      this.props.match.params.callId
    );
    this.setState({
      videoChat: responce.data,
    });
  }

  componentDidMount() {
    this.getData();
  }

  toHHMMSS = (secs: any) => {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");
  };

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/"></IonBackButton>
            </IonButtons>
            <IonTitle>Видеозвонок №{this.props.match.params.callId}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          {this.state.videoChat == null ? (
            false
          ) : (
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Информация о видеозвонке</IonCardTitle>
                <IonCardSubtitle>
                  Видеозвонок №{this.state.videoChat.id}
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent className="ion-no-padding">
                <IonItemDivider>Детали звонка</IonItemDivider>
                <IonItem>
                  <IonLabel>
                    <h2>{this.toHHMMSS(this.state.videoChat.duration)}</h2>
                    <p>Длительность звонка</p>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>
                    <h2>{new Date(this.state.videoChat.created).toString()}</h2>
                    <p>Ученик начал звонок</p>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>
                    <h2>
                      {new Date(
                        this.state.videoChat.teacher_connected
                      ).toString()}
                    </h2>
                    <p>Учитель соединился к звонку</p>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>
                    <h2>
                      {new Date(
                        this.state.videoChat.call_ended
                      ).toString()}
                    </h2>
                    <p>Звонок завершился</p>
                  </IonLabel>
                </IonItem>
                <IonItemDivider>Финансы</IonItemDivider>
                <IonItem>
                  <IonLabel>
                    <h2>
                      {this.state.videoChat.studentTransaction?.amount ??
                        "Транзкция не найдена"}
                    </h2>
                    <p>Заплатил ученик</p>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>
                    <h2>
                      {this.state.videoChat.teacherTransaction?.amount ??
                        "Транзкция не найдена"}
                    </h2>
                    <p>Заработал учитель</p>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel>
                    <h2>
                      {this.state.videoChat.comissionTransaction?.amount ??
                        "Транзкция не найдена"}
                    </h2>
                    <p>Заработано сервисом</p>
                  </IonLabel>
                </IonItem>

                <IonItemDivider>Участники</IonItemDivider>
                <IonItem
                  routerLink={"/users/" + this.state.videoChat.teacher.id}
                  button
                >
                  <IonLabel>
                    <h2>{this.state.videoChat.teacher.name}</h2>
                    <p>Учитель</p>
                  </IonLabel>
                </IonItem>
                <IonItem
                  routerLink={"/users/" + this.state.videoChat.pupil.id}
                  button
                >
                  <IonLabel>
                    <h2>{this.state.videoChat.pupil.name}</h2>
                    <p>Ученик</p>
                  </IonLabel>
                </IonItem>
                <IonItemDivider>Технические детали звонка</IonItemDivider>
                <IonItem>
                  <IonLabel className="ion-text-wrap">
                    <h2>SDP учителя</h2>
                    <p>{this.state.videoChat.answer_sdp}</p>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel className="ion-text-wrap">
                    <h2>IP адреса учителя</h2>
                    {this.state.videoChat.teacherIpAddress.map((ip: any) => (
                      <p key={ip}>{ip}</p>
                    ))}
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel className="ion-text-wrap">
                    <h2>SDP ученика</h2>
                    <p>{this.state.videoChat.offer_sdp}</p>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel className="ion-text-wrap">
                    <h2>IP адреса ученика</h2>
                    {this.state.videoChat.pupilIpAddress.map((ip: any) => (
                      <p key={ip}>{ip}</p>
                    ))}
                  </IonLabel>
                </IonItem>
              </IonCardContent>
            </IonCard>
          )}
        </IonContent>
      </IonPage>
    );
  }
}
