import React, { useRef, RefObject } from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonItemDivider,
  IonItem,
  IonLabel,
  IonDatetime,
} from "@ionic/react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import API from "../API";
export default class extends React.Component {
  state = {
    finances: [],
    areas: [],
  };

  filterStartDate = new Date().toISOString();
  filterEndDate = new Date().toISOString();

  async getData() {
    let responce = await API.getFinances(
      this.filterStartDate.split("T")[0],
      this.filterEndDate.split("T")[0]
    );

    this.setState({
      finances: responce.data,
      areas: responce.areas,
    });
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Финансы</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonItemDivider>Интервал времени</IonItemDivider>
          <IonItem>
            <IonLabel position="floating">Выберите дату от</IonLabel>
            <IonDatetime
              displayFormat="MM/DD/YYYY"
              value={this.filterStartDate}
              onIonChange={(event) => {
                this.filterStartDate =
                  event.detail.value ?? this.filterStartDate;
                this.getData();
              }}
            ></IonDatetime>
          </IonItem>

          <IonItem>
            <IonLabel position="floating">Выберите дату до</IonLabel>
            <IonDatetime
              displayFormat="MM/DD/YYYY"
              value={this.filterEndDate}
              onIonChange={(event) => {
                this.filterEndDate = event.detail.value ?? this.filterEndDate;
                this.getData();
              }}
            ></IonDatetime>
          </IonItem>

          <AreaChart
            width={900}
            height={400}
            data={this.state.finances}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            {this.state.areas.map((area: any) => {
              return (
                <Area
                  key={area.status}
                  type="monotone"
                  dataKey={area.status}
                  stroke={area.color}
                  fill={area.color}
                  name={area.description}
                />
              );
            })}
          </AreaChart>
        </IonContent>
      </IonPage>
    );
  }
}
