import {
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonContent,
  IonList,
  IonLabel,
  IonItem,
  IonFooter,
  IonInput,
  IonButton,
  IonBackButton,
  IonAlert,
  IonIcon,
} from "@ionic/react";
import React, { RefObject } from "react";
import { RouteComponentProps } from "react-router";
import API from "../API";
import UserService from "../UserService";
import { sendMessage, setNewMessageCallback } from "../SignallingChannel";
import { MessageList } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import { attach } from "ionicons/icons";

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

interface ChooseChatProps
  extends RouteComponentProps<{
    chatId: string;
    interlocutorId: string;
  }> {}

export default class extends React.Component<ChooseChatProps> {
  state = {
    loading: false,
    messages: [],
    tmpComment: "",
  };

  page = 0;

  currentUserId = UserService.getUserId();

  imagePickerRef: RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props);

    this.imagePickerRef = React.createRef();
  }

  async getChats() {
    let _chats = await API.getMessages(
      this.props.match.params.chatId,
      this.page
    );

    this.setState({
      messages: _chats["data"].reverse(),
    });
  }

  componentDidMount() {
    this.getChats();

    setNewMessageCallback((data) => {
      this.setState({
        messages: [...this.state.messages, data],
      });
    });
  }

  sendMessageButtonTapped() {
    sendMessage(this.props.match.params.interlocutorId, this.state.tmpComment);
    this.setState({
      tmpComment: "",
    });
  }

  async onImageChoosed(e: any) {
    if (e.target.files[0].type.startsWith("image/")) {
      let image = await getBase64(e.target.files[0]);
      let responce = await API.uploadImage(image as string);
      console.log(responce);
      sendMessage(this.props.match.params.chatId, responce.data.name, true);
    } else {
      alert("Выберите фото а не файл");
    }
  }

  render() {
    return (
      <IonPage>
        <input
          ref={this.imagePickerRef}
          style={{ display: "none" }}
          type="file"
          onChange={this.onImageChoosed.bind(this)}
        />
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/"></IonBackButton>
            </IonButtons>
            <IonTitle>Чат с пользователем</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <MessageList
            className="message-list"
            lockable={true}
            toBottomHeight={"100%"}
            dataSource={this.state.messages.map((message: any) => {
              return {
                position:
                  this.currentUserId == message.from_user_id ? "right" : "left",
                type: message.type == "image" ? "photo" : "text",
                data:
                  message.type == "image"
                    ? {
                        uri: message.data,
                      }
                    : null,
                text: message.type == "image" ? "Изображение" : message.data,
                date: new Date(message.created),
              };
            })}
          />
        </IonContent>
        <IonFooter>
          <IonItem>
            <IonLabel position="floating">Ваше сообщение</IonLabel>
            <IonInput
              value={this.state.tmpComment}
              onIonChange={(event) =>
                (this.state.tmpComment = event.detail.value!)
              }
            ></IonInput>
            <IonButton
              slot="end"
              onClick={() => {
                this.imagePickerRef.current?.click();
              }}
            >
              <IonIcon icon={attach}></IonIcon>
            </IonButton>
            <IonButton
              slot="end"
              onClick={() => this.sendMessageButtonTapped()}
            >
              Отпр.
            </IonButton>
          </IonItem>
        </IonFooter>
      </IonPage>
    );
  }
}
