import React from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonRow,
  IonCol,
  IonLabel,
  IonItem,
  IonAvatar,
} from "@ionic/react";
import API from "../API";

export default class extends React.Component {
  state = {
    users: [],
  };

  componentDidMount() {
    API.getUsersList().then((res: any) => {
      this.setState({
        users: res.data,
      });
    });
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Пользователи</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          {this.state.users.map((user: any) => {
            return (
              <IonItem key={user.id} routerLink={"/users/" + user.id} button>
                <IonAvatar slot="start">
                  <img src={user.avatar} />
                </IonAvatar>
                <IonLabel>
                  <h2>{user.name}</h2>
                  <p>
                    {user.is_teacher ? "Учитель" : "Ученик"} | Создан:{" "}
                    {user.created}
                  </p>
                </IonLabel>
              </IonItem>
            );
          })}
        </IonContent>
      </IonPage>
    );
  }
}
