import React from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  withIonLifeCycle,
} from "@ionic/react";
import API from "../API";
import { atCircle, mailUnread } from "ionicons/icons";

export default withIonLifeCycle(
  class extends React.Component {
    state = {
      chats: [],
    };

    ionViewDidEnter() {
      API.getChats().then((res) => this.setState({ chats: res["data"] }));
    }

    getUnreadMessagesCount(chat: any) {
      return chat.from_user_id == 1
        ? chat.from_user_unread_messages_count
        : chat.to_user_unread_messages_count;
    }

    render() {
      return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonMenuButton />
              </IonButtons>
              <IonTitle>Чат с пользователями</IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonList>
              {this.state.chats.map((chat: any) => {
                return (
                  <IonItem
                    routerLink={
                      "/support/" +
                      chat.id +
                      "/" +
                      (chat.from_user_id == 1
                        ? chat.to_user_id
                        : chat.from_user_id)
                    }
                    key={chat.id}
                  >
                    {this.getUnreadMessagesCount(chat) == 0 ? (
                      false
                    ) : (
                      <IonIcon
                        slot="start"
                        icon={mailUnread}
                        color="success"
                      ></IonIcon>
                    )}
                    <IonLabel>
                      <h2>
                        {chat.to_user_id == 1
                          ? chat.fromUser.name
                          : chat.toUser.name}
                      </h2>
                      <p>
                        {chat.last_message}
                        <br/>
                        {new Date(chat.last_message_sended).toString()}
                      </p>
                    </IonLabel>
                  </IonItem>
                );
              })}
            </IonList>
          </IonContent>
        </IonPage>
      );
    }
  }
);
