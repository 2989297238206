import React from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonItemDivider,
  IonCheckbox,
  IonButton,
} from "@ionic/react";
import API from "../API";

export default class extends React.Component<any> {
  state = {
    autoAcceptEnabled: null,
    teacherRequests: [],
  };

  async getData() {
    let json = await API.getTeacherRequests();

    this.setState(json.data);
  }

  async changeAutoAcceptState(val: boolean) {
    await API.changeAutoAcceptStatus(val).then(() => {
      this.getData();
    });
  }

  async changeRequestState(requestId: any, state: any) {
    await API.changeTeacherRequest(requestId, state).then(() => {
      this.getData();
    });
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Топ интересов</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList>
            <IonItemDivider>Настройки</IonItemDivider>
            {this.state.autoAcceptEnabled != null ? (
              <IonItem>
                <IonLabel>Авто принятие завки</IonLabel>
                <IonCheckbox
                  checked={this.state.autoAcceptEnabled == true}
                  onIonChange={(val) =>
                    this.changeAutoAcceptState(val.detail.checked)
                  }
                  slot="end"
                ></IonCheckbox>
              </IonItem>
            ) : (
              false
            )}

            <IonItemDivider>Заявки</IonItemDivider>

            {this.state.teacherRequests.map((request: any) => (
              <IonItem key={request.id}>
                <IonLabel>
                  <h3>Номер телефона: {request.phone}</h3>
                  <p>Email: {request.email}</p>
                  <p>О себе: {request.about}</p>
                </IonLabel>
                <IonButton routerLink={"/users/" + request.user_id}>
                  Информация
                </IonButton>
                <IonButton
                  color="danger"
                  onClick={() => {
                    this.changeRequestState(request.id, 1);
                  }}
                >
                  Отклонить
                </IonButton>
                <IonButton
                  color="success"
                  onClick={() => {
                    this.changeRequestState(request.id, 2);
                  }}
                >
                  Одобрить
                </IonButton>
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
}
