import io from "socket.io-client";
import API from "./API";
import UserService from "./UserService";
import { signallingAddress } from "./Constants";

let client: SocketIOClient.Socket;

var newMessageCallback: (data: any) => void;

export function setNewMessageCallback(callback: (data: any) => void) {
  newMessageCallback = callback;
}

export function connectToSocketIO() {
  client = io(signallingAddress, {});

  client.on("connection", (socket: any) => {
    console.log(socket);
    console.log("Connected");
  });

  client.on("connect", async () => {
    console.log("Connected 2");

    client.emit("register", {
      accessToken: UserService.getToken(),
    });
  });

  client.on("newChatMessage", (data: any) => {
    if (newMessageCallback != null) {
      newMessageCallback(data);
    }
  });

  client.connect();
}

export async function sendMessage(
  forUserId: any,
  message: any,
  isImage: boolean = false
) {
  client.emit("newChatMessage", {
    forUserId: forUserId,
    data: message,
    type: isImage ? "image" : "text",
  });
}
