const forceProd = true;

export const serverAddress =
  document.location.hostname == "localhost" && !forceProd
    ? "http://localhost:8080/api/v1/"
    : "https://api2.talkout.me/api/v1/";
export const signallingAddress =
  document.location.hostname == "localhost" && !forceProd
    ? "http://localhost:8080/"
    : "https://api2.talkout.me/";
