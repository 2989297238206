import { serverAddress } from "./Constants";
import UserService from "./UserService";

export default class {
  static headers = { "Content-type": "application/json" };

  static errorMiddleware(request: any) {
    if (request.success == false) {
      if (request.code == 1) {
        UserService.dropUserData();
        alert("Неккоректный токен. Нужно переавторизоваться");
        document.location.href = "/";
      } else {
        alert(JSON.stringify(request.error));
      }
    } else {
      return request;
    }
  }

  static getBody(data: any) {
    return JSON.stringify({
      ...data,
      locale: "ru",
    });
  }

  static async requestCode(email: any) {
    let responce = await fetch(serverAddress + "auth/requestCode", {
      method: "POST",
      body: this.getBody({
        authType: "email",
        email: email,
      }),
      headers: this.headers,
    });

    return await responce.json();
  }

  static async confirmCode(email: any, code: any) {
    let responce = await fetch(serverAddress + "auth/confirmCode", {
      method: "POST",
      body: this.getBody({
        authType: "email",
        email: email,
        code: code,
      }),
      headers: this.headers,
    });

    return await responce.json();
  }

  static async getChats() {
    let responce = await fetch(serverAddress + "chat/list", {
      method: "POST",
      body: this.getBody({
        accessToken: UserService.getToken(),
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async getMessages(chatId: any, page: any) {
    let responce = await fetch(serverAddress + "chat/messages", {
      method: "POST",
      body: this.getBody({
        accessToken: UserService.getToken(),
        chatId: chatId,
        page: page,
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async getVideocalls(page: number) {
    let responce = await fetch(serverAddress + "admin/videocalls/getHistory", {
      method: "POST",
      body: this.getBody({
        accessToken: UserService.getToken(),
        page: page,
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async getVideocallsFullInfo(videoChatId: any) {
    let responce = await fetch(serverAddress + "admin/videocalls/getFullInfo", {
      method: "POST",
      body: this.getBody({
        accessToken: UserService.getToken(),
        videoChatId: videoChatId,
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async getUsersList() {
    let responce = await fetch(serverAddress + "admin/users/list", {
      method: "POST",
      body: this.getBody({
        accessToken: UserService.getToken(),
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async getUserInfo(userId: any) {
    let responce = await fetch(serverAddress + "admin/users/get", {
      method: "POST",
      body: this.getBody({
        accessToken: UserService.getToken(),
        userId: userId,
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async getTeacherRequests() {
    let responce = await fetch(serverAddress + "admin/teachers/requests", {
      method: "POST",
      body: this.getBody({
        accessToken: UserService.getToken(),
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async changeAutoAcceptStatus(enabled: any) {
    let responce = await fetch(
      serverAddress + "admin/config/changeAutoAccept",
      {
        method: "POST",
        body: this.getBody({
          accessToken: UserService.getToken(),
          enabled: enabled,
        }),
        headers: this.headers,
      }
    );

    return this.errorMiddleware(await responce.json());
  }

  static async changeTeacherRequest(requestId: any, status: any) {
    let responce = await fetch(
      serverAddress + "admin/teachers/changeRequestStatus",
      {
        method: "POST",
        body: this.getBody({
          accessToken: UserService.getToken(),
          requestId: requestId,
          status: status,
        }),
        headers: this.headers,
      }
    );

    return this.errorMiddleware(await responce.json());
  }

  static async getTopics(startDate: string, endDate: string) {
    let responce = await fetch(serverAddress + "admin/topics/getList", {
      method: "POST",
      body: this.getBody({
        accessToken: UserService.getToken(),
        startDate: startDate,
        endDate: endDate,
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async getFinances(startDate: string, endDate: string) {
    let responce = await fetch(serverAddress + "admin/finances/getStatistics", {
      method: "POST",
      body: this.getBody({
        accessToken: UserService.getToken(),
        startDate: startDate,
        endDate: endDate,
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async dashboard(startDate: string, endDate: string) {
    let responce = await fetch(serverAddress + "admin/statistics/dashboard", {
      method: "POST",
      body: this.getBody({
        accessToken: UserService.getToken(),
        startDate: startDate,
        endDate: endDate,
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async changeSessionState(
    sessionId: string,
    userId: string,
    isActive: boolean
  ) {
    let responce = await fetch(
      serverAddress + "admin/user/changeSessionState",
      {
        method: "POST",
        body: this.getBody({
          accessToken: UserService.getToken(),
          isActive: isActive,
          sessionId: sessionId,
          userId: userId,
        }),
        headers: this.headers,
      }
    );

    return this.errorMiddleware(await responce.json());
  }

  static async getWithdrawTransactions() {
    let responce = await fetch(
      serverAddress + "admin/finances/getWithdrawTransactions",
      {
        method: "POST",
        body: this.getBody({
          accessToken: UserService.getToken(),
        }),
        headers: this.headers,
      }
    );

    return this.errorMiddleware(await responce.json());
  }

  static async changeWithdrawStatus(transactionId: any, action: string) {
    let responce = await fetch(
      serverAddress + "admin/finances/changeWithdrawStatus",
      {
        method: "POST",
        body: this.getBody({
          accessToken: UserService.getToken(),
          transactionId: transactionId,
          action: action,
        }),
        headers: this.headers,
      }
    );

    return this.errorMiddleware(await responce.json());
  }

  static async getWithdrawInvoice(transactionId: any) {
    let responce = await fetch(
      serverAddress + "admin/finances/getInvoice",
      {
        method: "POST",
        body: this.getBody({
          accessToken: UserService.getToken(),
          transactionId: transactionId,
        }),
        headers: this.headers,
      }
    );

    return this.errorMiddleware(await responce.json());
  }

  static async uploadImage(image: string) {
    let responce = await fetch(serverAddress + "user/uploadImage", {
      method: "POST",
      body: this.getBody({
        accessToken: UserService.getToken(),
        data: image,
      }),
      headers: this.headers,
    });

    return this.errorMiddleware(await responce.json());
  }

  static async getChatByUserId(userId: any) {
    let responce = await fetch(
      serverAddress + "chat/get",
      {
        method: "POST",
        body: this.getBody({
          accessToken: UserService.getToken(),
          toUserId: userId,
        }),
        headers: this.headers,
      }
    );

    return this.errorMiddleware(await responce.json());
  }
}
