import React from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonTabButton,
  IonIcon,
  IonSpinner,
} from "@ionic/react";
import API from "../API";
import { download } from "ionicons/icons";

export default class extends React.Component {
  state = {
    videocalls: [],
    loading: false,
  };

  page = -1;

  componentDidMount() {
    this.loadMore();
  }

  loadMore() {
    if (this.state.loading) {
      return;
    }

    this.page += 1;
    this.setState({
      loading: true,
    });
    API.getVideocalls(this.page).then((res: any) => {
      this.setState({
        videocalls: [...this.state.videocalls, ...res.data],
        loading: false,
      });
    });
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Видеозвонки</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList>
            {this.state.videocalls.map((videocall: any) => {
              return (
                <IonItem
                  key={videocall.id}
                  routerLink={"/videocalls/" + videocall.id}
                  button
                >
                  <IonLabel>
                    <h2>Видеочат №{videocall.id}</h2>
                    <p>
                      Длительность {Math.round(videocall.duration / 60)} мин.
                    </p>
                  </IonLabel>
                  <IonLabel>
                    <p>Ученик: {videocall.student_name}</p>
                    <p>Учитель: {videocall.teacher_name}</p>
                  </IonLabel>
                </IonItem>
              );
            })}
            <IonTabButton onClick={() => this.loadMore()}>
              {this.state.loading ? (
                <IonSpinner></IonSpinner>
              ) : (
                <IonIcon icon={download}></IonIcon>
              )}

              <IonLabel>Загрузить еще</IonLabel>
            </IonTabButton>
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
}
