import React from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonRow,
  IonCol,
  IonLabel,
  IonItemDivider,
  IonItem,
  IonDatetime,
} from "@ionic/react";
import Chart from "react-google-charts";
import API from "../API";
import {
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
} from "recharts";
import UserService from "../UserService";

export default class extends React.Component {
  state = {
    dates: [],
    total: {
      revenue: 0,
      videocallsCount: 0,
      lastActiveDateCount: 0,
      totalFinances: 0
    },
  };

  filterStartDate = new Date().toISOString();
  filterEndDate = new Date().toISOString();

  async getData() {
    let responce = await API.dashboard(
      this.filterStartDate.split("T")[0],
      this.filterEndDate.split("T")[0]
    );

    this.setState({
      dates: responce.data,
      total: responce.total,
    });
  }

  componentDidMount() {
    if (UserService.isLogged()){
      this.getData();
    }
  }

  render() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>Главная</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonItemDivider>Интервал времени</IonItemDivider>
          <IonItem>
            <IonLabel position="floating">Выберите дату от</IonLabel>
            <IonDatetime
              displayFormat="MM/DD/YYYY"
              value={this.filterStartDate}
              onIonChange={(event) => {
                this.filterStartDate =
                  event.detail.value ?? this.filterStartDate;
                this.getData();
              }}
            ></IonDatetime>
          </IonItem>

          <IonItem>
            <IonLabel position="floating">Выберите дату до</IonLabel>
            <IonDatetime
              displayFormat="MM/DD/YYYY"
              value={this.filterEndDate}
              onIonChange={(event) => {
                this.filterEndDate = event.detail.value ?? this.filterEndDate;
                this.getData();
              }}
            ></IonDatetime>
          </IonItem>

          <div className="ion-padding">
            <IonRow>
              <IonCol size="3">
                <IonLabel>
                  <p>Запусков приложения</p>
                  <h1>{this.state.total.lastActiveDateCount}</h1>
                </IonLabel>
              </IonCol>
              <IonCol size="3">
                <IonLabel>
                  <p>Звонков совершено</p>
                  <h1>{this.state.total.videocallsCount}</h1>
                </IonLabel>
              </IonCol>
              <IonCol size="3">
                <IonLabel>
                  <p>Заработок TO</p>
                  <h1 style={{ color: "green" }}>
                    {this.state.total.revenue.toFixed(2)} €
                  </h1>
                </IonLabel>
              </IonCol>
              <IonCol size="3">
                <IonLabel>
                  <p>Поступление</p>
                  <h1 style={{ color: "#179600" }}>
                    {this.state.total.totalFinances.toFixed(2)} €
                  </h1>
                </IonLabel>
              </IonCol>
            </IonRow>
          </div>
          <AreaChart
            width={900}
            height={400}
            data={this.state.dates}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="lastActiveDateCount"
              stroke="#0000ff"
              fill="#0000ff"
              name="Запусков приложения"
            />
            <Area
              type="monotone"
              dataKey="videocallsCount"
              stroke="#ff0066"
              fill="#ff0066"
              name="Звонков совершено"
            />
            <Area
              type="monotone"
              dataKey="revenue"
              stroke="#009900"
              fill="#009900"
              name="Заработок TO"
            />
            <Area
              type="monotone"
              dataKey="totalFinances"
              stroke="#179600"
              fill="#179600"
              name="Поступление"
            />
          </AreaChart>
        </IonContent>
      </IonPage>
    );
  }
}
