import React from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  withIonLifeCycle,
  IonButton,
} from "@ionic/react";
import API from "../API";
import { atCircle, mailUnread } from "ionicons/icons";
import { serverAddress, signallingAddress } from "../Constants";

export default withIonLifeCycle(
  class extends React.Component {
    state = {
      transactions: [],
    };

    ionViewDidEnter() {
      API.getWithdrawTransactions().then((res) =>
        this.setState({ transactions: res["data"] })
      );
    }

    render() {
      return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonMenuButton />
              </IonButtons>
              <IonTitle>Вывод денег</IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            <IonList>
              {this.state.transactions.map((transaction: any) => {
                return (
                  <IonItem key={transaction.id}>
                    <IonLabel className="ion-text-wrap">
                      <h2>
                        #{transaction.user_id} - {transaction.user_name}
                      </h2>
                      <p>
                        Последний зафиксированный баланс:{" "}
                        <strong>{transaction.user_balance / 100}</strong> евро
                      </p>
                      <p>
                        Запрос на вывод:{" "}
                        <strong>{(transaction.amount * -1) / 100}</strong> евро
                      </p>
                      <p>
                        Дата запроса: {new Date(transaction.created).toString()}
                      </p>
                    </IonLabel>

                    <IonButtons slot="end">
                      <IonButton
                        onClick={async () => {
                          let invoice = await API.getWithdrawInvoice(
                            transaction.id
                          );
                          window.open(signallingAddress + invoice.data, "_blank");
                        }}
                        color="primary"
                      >
                        Счет
                      </IonButton>
                      <IonButton
                        onClick={async () => {
                          let transactions = await API.changeWithdrawStatus(
                            transaction.id,
                            "approved"
                          );
                          this.setState({
                            transactions: transactions.data,
                          });
                        }}
                        color="success"
                      >
                        Выведен
                      </IonButton>
                      <IonButton
                        onClick={async () => {
                          let transactions = await API.changeWithdrawStatus(
                            transaction.id,
                            "decline"
                          );
                          this.setState({
                            transactions: transactions.data,
                          });
                        }}
                        color="danger"
                      >
                        Отказать
                      </IonButton>
                    </IonButtons>
                  </IonItem>
                );
              })}
            </IonList>
          </IonContent>
        </IonPage>
      );
    }
  }
);
